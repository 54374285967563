import Response from './response';

export default class Singleton{    
    static websocket = null;
    static userId = 0;
    static sessionId = 0;
    static onMessageMap = new Map();
    static counter = 0;
    constructor(appPath){
        if (Singleton.websocket === null){            
            const wsURL = "ws://192.168.1.5:8090/tkjena/ws";
            Singleton.websocket = new WebSocket(wsURL);
            Singleton.websocket.onmessage = this.onmessage;
        }
    }
        
    resetSession(){
        this.userId = 0;
        this.sessionId = 0;  
        if (Singleton.websocket != null){ 
            Singleton.websocket.close();
        } 
        Singleton.websocket = null;         
    }
            
    send(jsonObject, responseHandler, onCloseHandler){
        jsonObject["sessionId"] = Singleton.sessionId;   
        const counterValue = this.getCounterValue();
        const tag = {
            id: counterValue
        }
        jsonObject["tag"] = tag;
        Singleton.onMessageMap.set(counterValue, responseHandler);
        if (arguments.length === 3){
            Singleton.websocket.onclose = onCloseHandler;     
        }
       
        Singleton.websocket.send(JSON.stringify(jsonObject));
    }

    // test(){
    // }
        
    onmessage(event) {
        let response;
        let tagId;
        try{        
            response = new Response(event.data); 
            tagId = response.getTagValue("id"); 
            if (!response.isSuccess()){
                //alert(response.getMessage());
            }else{
                const actionId = response.getActionId();            
                if (actionId === 1){ //SIGNIN                    
                    Singleton.sessionId = response.getResponseDataValue('sessionId');
                    Singleton.userId = response.getResponseDataValue('userId');
                }else if (actionId === 2){ //SIGNOUT
                    //resetSession();
                    return;
                }
            }            
            
            const responseHandler = Singleton.onMessageMap.get(tagId);            
            
            responseHandler(response);

            Singleton.onMessageMap.delete(tagId);
        }catch(error){
            Singleton.onMessageMap.delete(tagId);
            alert("Error: " + error.message);
            alert(error.stack);            
            const jsonObject = { 
                "step" : "onResponse",
                "errorMessage" : error.stack,                                
                "actionId" : -1,
                "errorRequestId" : response.getResponseInfoValue('requestId')
            };
        }
    }
    
    // onclose(){        
    // }        
    
    getCounterValue(){
        Singleton.counter = Singleton.counter + 1;
        return Singleton.counter;
    }
}
