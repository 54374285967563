<template>
    <ul class="full-width" id="firstheader">            
        <li class="float-left" id="logo"><a :href="home"><span class="logotext">Livppy</span><span class="logotextcursive"> Lab</span></a></li>
        
        <!-- <li v-if="signedIn" class="float-right"><a href="#" @click="captueClick">Signin</a></li>
        <li v-else class="float-right"><a href="#" @click="showSignoutForm">Signout</a></li> -->
    </ul>      
</template>
<script>
//import util from '../util';
import Singleton from '../singleton';
export default {
    data(){
        return {
            home: process.env.VUE_APP_HOME_URL
        }
    },
    methods: {
        captueClick(event){
            const appPath = this.$store.state.appPath;
            const promise = new Promise (function(resolve, reject){
                    const singleton = new Singleton(appPath);    
                    setTimeout(function () { 
                        if (Singleton.websocket.readyState === 1) {
                            resolve(singleton);                            
                        }else{
                            singleton.resetSession();
                            reject(new Error('Not Allowed Yet'));                            
                        }
                    }, 3000, appPath);            
                });
            promise.then(this.showSigninForm, function(error){alert(error)}) 
            this.$store.commit('eventFormPos', {x: event.clientX, y: event.clientY});
        },
        showSigninForm(singleton){
            this.$store.commit('singleton', singleton);                    
            this.$store.commit('signinFormActive', true);                               
        },
        showSignoutForm(event){
             this.$store.commit('eventFormPos', {x: event.clientX, y: event.clientY});
             this.$store.commit('signoutFormActive', true);
        }
    },
    computed:{
        signedIn(){
            return this.$store.state.userId === 0; 
        }
    }  
}
</script>

<style scoped>
    .float-left{
        float: left;
    }

    ul#firstheader > li#logo{   
        /* margin-top: 15px;
        margin-left: 15px;   */
        padding-top: 2px; 
        padding-bottom: 2px; 
    }
    ul#firstheader > li#logo > a{
        height: 30px;
        font-size: 20px;
        font-weight: bold;
        font-family: Verdana, Helvetica, Monospace;
        color: white;
        cursor: pointer;                
        padding: 4px;
        text-align: center;
        text-shadow: 1px 1px 1px #000;
        border-radius: 3px;
        text-decoration: none;
        letter-spacing: 3px;
    }   

    ul#firstheader > li#logo > a:hover{
        color: white;
    }
    .logotext{
        /* font: 400 13px 'Open Sans',Arial,sans-serif; */
        font-family: Verdana;
    }
    .logotextcursive{
        font-family: Verdana;
        color: #ffd55a;
    }

    ul#firstheader{
        display: inline-block;
        list-style: none;  
        margin: 0; 
        padding: 0;    
        overflow: hidden; 
    }
    
    ul#firstheader > li {   
        margin: 0;
        padding: 0;
    }
    
    ul#firstheader > li:not(#logo){   
        margin-top: 2px;
        margin-left: 4px;  
        margin-right: 1px;
        padding-top: 4px; 
        padding-bottom: 4px; 
    }
            
    ul#firstheader > li:not(#logo) > a{
        font-weight: bold;
        color: white; 
        cursor: pointer;                
        padding: 2px;
        text-align: center;
        text-shadow: 1px 1px 1px #000;
        border-radius: 3px;
        text-decoration: none;
        font-size: var(--menu-font-size);
    }        
    
    ul#firstheader > li:not(#logo) > a:hover{
        color: var(--menu-hover-color);
    }
    ul#firstheader > li:not(#logo) > a.selected{
        color: var(--menu-selected-color);
    }
</style>
